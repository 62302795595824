* {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

hr {
  margin: 1rem 0;
}

.control-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 1000px) {
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
