header {
  margin-top: 1rem;
}

nav {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
}

ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3rem;
}

nav > ul > li {
  display: flex;
  justify-content: center;
}

a {
  color: black;
  text-decoration: none;
}
